// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  appId: "1:577480071020:web:c38cf400a502ceea94c549",
  apiKey: "AIzaSyAmFkCrloueMHvZxl6wqoKlN0iHLcGsGyY",
  authDomain: "gmc-backend-ae063.firebaseapp.com",
  storageBucket: "gmc-backend-ae063.appspot.com",
  messagingSenderId: "577480071020",
  projectId: "gmc-backend-ae063",
  measurementId: "G-S2PJ8FX0FD",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firestoreDB = getFirestore(firebaseApp);

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
  });
}

export const handleObj = (data) => Object.fromEntries(Object.entries(data || {})?.filter(([key, val]) => val));