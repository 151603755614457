import React, { useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { Button, Drawer, Dropdown } from 'antd';
import { FiChevronDown } from 'react-icons/fi';
import Logo from '../logo';
import { Link } from 'react-router-dom';

export function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const onIsOpen = () => setIsOpen(true)
  const onIsClose = () => setIsOpen(false)

  const getChild = ({key, label}) => ({key, label: (typeof label === 'string') ? <Link to={key} key={key} className='block text-sm font-normal hover:text-blue'>{label}</Link> : label})

  const products = [
    {
      key: "dynamic-365-finance-and-operations",
      label: "Dynamics 365 Finance and Operations",
    },
    {
      key: "dynamic-business-central",
      label: "Dynamics Business Central",
    },
    {
      key: "microsoft-365",
      label: "Microsoft 365",
    },
    {
      key: "sharepoint",
      label: "SharePoint",
    },
    {
      key: "power-bi",
      label: "Power BI",
    },
    {
      key: "cisco",
      label: "Cisco",
    },
    {
      key: "azure",
      label: "Azure",
    },
    {
      key: "oracle",
      label: "Oracle",
    },
    {
      key: "sophos",
      label: "Sophos",
    },
  ]

  const services = [
    {
      key: "enterprise-resource-planning",
      label: <Dropdown menu={{ items: [
        {key: "#business-intelligence", label: "Business Intelligence"},
        {key: "#system-process-automation", label: "System Process Automation"},
        {key: "#it-network-service", label: "IT Network Service"},
        {key: "#system-integration", label: "System integration"},
      ].map(getChild)}}>
        <Link to="#enterprise-resource-planning" className='flex items-center gap-2 text-sm font-normal hover:text-blue'><span>Enterprise Resource Planning</span> <FiChevronDown /></Link> 
      </Dropdown>
    },
    {
      key: "#oracle-database-management",
      label: "Oracle Database Management"
    },
    {
      key: "#it-security-and-cloud-service",
      label: <Dropdown menu={{ items: [
        {key: "#cloud-service", label: "Cloud Service"},
      ].map(getChild)}}>
        <Link to="#it-security-and-cloud-service" className='flex items-center gap-2 text-sm font-normal hover:text-blue'><span>IT Security & Cloud Service</span> <FiChevronDown /></Link> 
      </Dropdown>
    },
  ]

  const items = [
    {
      key: "/products",
      label: "Products",
      children: products
    },
    {
      key: "#services",
      label: "Our Services",
      children: services,
    },
    {
      key: "/about",
      label: "About Us",
    },
    {
      key: "/contact",
      label: "Contact Us",
    },
    {
      key: "/blogs",
      label: "Blog",
    },
    {
      key: "/medias",
      label: "Multimedia",
    },
  ];

  const MenuContent = () => (
    <div className='flex flex-col md:flex-row justify-between items-center gap-10'>
      {items.map(({key, label, children}) => children ? (<Dropdown menu={{ items: children.map(getChild) }}>
        <Link to={key} key={key} className='flex items-center gap-2 text-sm font-normal hover:text-blue'><span>{label}</span> <FiChevronDown /></Link> 
      </Dropdown>) : (<Link to={key} key={key} className='block text-sm font-normal hover:text-blue'>{label}</Link>))}
    </div>
  )

  return (
    <div id='navbar' className='w-full sticky z-10 top-0 backdrop-blur-xl shadow-md p-5 md:px-10 lg:px-20 xl:px-32 md:flex justify-between items-center'>
      <div className='w-full md:w-auto flex justify-between items-center'>
        <Logo />
        <Button
          size='large'
          onClick={onIsOpen}
          className='md:hidden'
          icon={<AiOutlineMenu />}
        />
      </div>
      <div className='hidden md:block'>
        <MenuContent />
      </div>

      <Drawer
        width={250}
        title={<Logo />}
        placement="left"
        onClose={onIsClose}
        closable={false}
        open={isOpen}
      >
        <MenuContent />
      </Drawer>
    </div>
  )
}

export default Header