
import React, { Suspense, lazy } from "react";
import Layout from "./layouts";
import { RecoilRoot } from "recoil";
import Loading from "./components/loading";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";


const Ms365 = lazy(() => import("./pages/ms-365"))
const HomePage = lazy(() => import("./pages/home"))
const NotFoundPage = lazy(() => import("./pages/404"))
const MediasPage = lazy(() => import("./pages/medias"))
const AboutPage = lazy(() => import("./pages/about"))
const BlogsPage = lazy(() => import("./pages/blogs"))
const BlogPage = lazy(() => import("./pages/blog"))
const CiscoPage = lazy(() => import("./pages/cisco"))
const AzurePage = lazy(() => import("./pages/azure"))
const PowerBI = lazy(() => import("./pages/power-bi"))
const OraclePage = lazy(() => import("./pages/oracle"))
const ContactPage = lazy(() => import("./pages/contact"))
const Sharepoint = lazy(() => import("./pages/sharepoint"))
const ProductsPage = lazy(() => import("./pages/products"))
const MsDynamicBC = lazy(() => import("./pages/ms-dynamic-bc"))
const MsDynamic365 = lazy(() => import("./pages/ms-dynamic-365"))

// admin routes >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Admin = lazy(() => import("./pages/admin"))


const queryClient = new QueryClient()

function App() {
  // window.addEventListener("scroll", () => {
  //   const scrollHeight = Math.ceil(window.scrollY);
  //   const bodyHeight = document.body.offsetHeight;
  //   const menu = document.getElementById("navbar")?.classList;
  //   if (scrollHeight >= 130 && bodyHeight >= 1200) {
  //     menu?.add("fixed");
  //   } else {
  //     menu?.remove("fixed");
  //   }
  // });

  const routes = [
    {
      path: "/",
      component: <HomePage />,
    },
    {
      path: "/about",
      component: <AboutPage />,
    },
    {
      path: "/contact",
      component: <ContactPage />,
    },
    {
      path: "/blogs",
      component: <BlogsPage />,
    },
    {
      path: "/blog/:id",
      component: <BlogPage />,
    },
    {
      path: "/medias",
      component: <MediasPage />,
    },
    {
      path: "/products",
      component: <ProductsPage />,
    },
    {
      path: "/dynamic-365-finance-and-operations",
      component: <MsDynamic365 />
    },
    {
      path: "/dynamic-business-central",
      component: <MsDynamicBC />
    },
    {
      path: "/microsoft-365",
      component: <Ms365 />
    },
    {
      path: "/sharepoint",
      component: <Sharepoint />
    },
    {
      path: "/power-bi",
      component: <PowerBI />
    },
    {
      path: "/cisco",
      component: <CiscoPage />
    },
    {
      path: "/azure",
      component: <AzurePage />
    },
    {
      path: "/oracle",
      component: <OraclePage />
    },


    // admin routes >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    {
      path: "/admin",
      component: <Admin />
    },


    // other routes >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    {
      path: "*",
      component: <NotFoundPage />
    }
  ];
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename='/'>
          <Layout>
            <Routes>
              {routes.map(({ path, component }) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={<Suspense fallback={<Loading />}>{component}</Suspense>}
                  />
                );
              })}
            </Routes>
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
