import { collection, getDocs } from "firebase/firestore";
import { firestoreDB } from "../utils";
import { useMutation, useQuery } from "react-query";
import { notification } from "antd";

export const useGetAuth = () => {
  const handleGet = async () => await getDocs(collection(firestoreDB, "auth"))
  .then(data => Promise.resolve(data?.docs?.map((doc) => ({...doc.data(), id:doc.id }))))
  return useQuery(["key:get_authentication"], handleGet, {
  onError: (err) => notification.error({
    message: "Error!",
    description: err?.message || "Something went wrong."
  })
})};

export const usePostContact = () => {
  const form = new FormData()
  const handlePost = async (payload) => {
    const data = {
      _wpcf7: 1319,
      _wpcf7_version: 5.4,
      _wpcf7_locale: "en_US",
      _wpcf7_unit_tag: "wpcf7-f1319-p293-o1",
      _wpcf7_container_post: 293,
      _wpcf7_ak_js: 1711544884569,
      ...payload
    }
    Object.entries(data).forEach(([key, val]) => form.append(key, val))
    return await fetch("https://grittymindsconcepts.com/wp-json/contact-form-7/v1/contact-forms/1319/feedback?_locale=user", {
    method: "POST",
    body: form,
  })
  .then(data => Promise.resolve(data.json()))
  .catch(error => Promise.reject(error.json()))
  }
  return useMutation((payload) => handlePost(payload), {
  onSuccess: (res) => notification.success({
    message: "Success!",
    description: res?.message || "Action successful."
  }),
  onError: (err) => notification.error({
    message: "Error!",
    description: err?.message || "Something went wrong."
  })
})};