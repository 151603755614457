import React, { useState } from 'react'
import { Button, Form, Image, Input, Modal, notification } from 'antd'
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi'
import icon from '../../assets/icons/icon.png'
import { useGetAuth } from '../../hooks/other'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import authAtom from '../../atoms/auth/auth.atom'

function Footer() {
  const [auth, setAuth] = useRecoilState(authAtom)
  const [isOpen, setIsOpen] = useState(false)
  const { data: getAuthData } = useGetAuth()
  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  const navigate = useNavigate()

  const handleAuth = (data) => {
    const { password } = getAuthData?.[0] || { password: "" }
    if(!password || (data?.password !== password)) return notification.error({
      description: "Invalid admin auth data.",
      message: "Error!",
    })
    setAuth({...auth, isLoggedIn: true})
    notification.success({
      description: "Welcome back admin.",
      message: "Success!",
    })
    navigate("/admin")
    onClose()
  }
  return (
    <div className='w-full bg-light border-t border-light'>
      <div className='p-5 sm:p-10 md:p-20 lg:px-40 !pb-5 grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-20'>
        <div className='space-y-5'>
          <div className='flex items-center gap-3'>
            <p className='text-xl font-semibold'>Gritty Mind Concept</p>
            <Image src={icon} alt='icon' preview={false} className='!w-[30px]' />
          </div>
          <p className='text-sm'>GMC Ltd is a company of repute with a dynamic and experienced team of professionals. We have over a decade of robust experience with customers across industries. Our expertise cuts across several areas of portfolios. Our pride is the delight we bring to our customers. We listen diligently and deliver smartly!</p>
        </div>
        <div className='space-y-5'>
          <p className='text-xl font-semibold'>Our Services</p>
          <ul className='list-disc px-5'>
            {[
              "Enterprise Resource Planning",
              "Business Intelligence",
              "System Process Automation",
              "IT Network Service",
              "System Integration",
              "Oracle Database Management",
              "IT Security and Cloud Service",
              "Cloud Service",
              "Microsoft Dynamics",
            ].map(d => (
              <li className='text-sm' key={d}>{d}</li>
            ))}
          </ul>
        </div>
        <div className=''>
          <p className='text-xl font-semibold'>Contact Us</p>
          <div className='space-y-2'>
            {[
              {icon: <FiMapPin />, title: "40b Seinde Calisto Cres, Orile Oshodi, Lagos 102214, Nigeria"},
              {icon: <FiPhone />, title: "+234 701 709 5720"},
              {icon: <FiPhone />, title: "+234 805 607 4893"},
              {icon: <FiMapPin />, title: "Unit 70, Eton Close, 241 Le Roux Avenue, Vorna Valley, 1686 Midrand, Johannesburg South Africa"},
              {icon: <FiPhone />, title: "+2773 551 5677"},
              {icon: <FiMail />, title: "info@grittymindsconcepts.com"},
            ].map(({icon, title}) => (
              <div className='flex items-center gap-3 text-sm'>
                <p className='text-base'>{icon}</p>
                <p className=''>{title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='p-1 bg-white' /> 
      <div className='py-2 px-5 sm:px-10 md:px-20 lg:px-40 flex flex-col md:flex-row md:justify-between items-center gap-1 text-sm'>
        <p>Copyright GMC {new Date().getFullYear()}</p>
        <p>Developed by: <a href='https://techend.com.ng/'>Techend LTD</a></p>
        <p>Admin: <Button onClick={onOpen} type='text' className='!bg-transparent'>Blog & Uploads</Button></p>
      </div> 

      <Modal
        title="Admin Verification"
        onCancel={onClose}
        footer={false}
        open={isOpen}
      >
        <Form onFinish={handleAuth} layout='vertical' initialValues={{email: "info@grittymindsconcepts.com"}} className='space-y-5'>
          <Form.Item label="Email" name="email" required>
            <Input required size='large' type='email' placeholder='Admin email' />
          </Form.Item>
          <Form.Item label="Password" name="password" required>
            <Input.Password required size='large' placeholder='Admin assword' />
          </Form.Item>
          <Button className='bg-blue' block size='large' type='primary' htmlType='submit'>Authenticate</Button>
        </Form>
      </Modal>
    </div>
  )
}

export default Footer