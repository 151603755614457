import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import authAtom from "../atoms/auth/auth.atom";
import { message } from "antd";

const Layout = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isLoggedIn } = useRecoilValue(authAtom)
  const [pPath, setPPath] = useState(document.referrer)

  const currentPath = pathname
  if (currentPath !== pPath) {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    })
  }

  setTimeout(() => setPPath(currentPath), 3000)

  const isAdmin = (/^\/admin(?=\/|$)/i.test(pathname))

  useEffect(() => {if (isAdmin && !isLoggedIn) {
    message.error({ content: "Page not accessible.", key: "updatable"})
    navigate("/")
  }}, [isLoggedIn, isAdmin, navigate])
  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

export default Layout;
